import React, { Component } from 'react';
import { Link } from 'gatsby';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    window.location.replace(process.env.GATSBY_FORGOT_PASSWORD);
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email } = this.state;

    const isInvalid = email === '';

    return (
        <div className="wrapper maxWidth">
      <form id="fullWidthForm" onSubmit={this.onSubmit}>
        <div>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
        </div>
        <button disabled={isInvalid} type="submit">
          Reset Password
        </button>
      </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="collapse-top">
    <Link to={ROUTES.PASSWORD_FORGET} className="button ghost full">Forgot Password?</Link>
  </p>
);

export default withFirebase(PasswordForgetForm);

export { PasswordForgetLink };
