import React from 'react';
import { PasswordForgetLink } from '../components/PasswordForget/index';
import Layout from '../App/components/layout';

const PasswordResetForm = () => (
        <Layout>
        <div className="wrapper maxWidth">
                <h1>We’ve sent your email password recovery options.</h1>
                
                <p>If there is an account associated with this email address, we will send you a link to reset your password.</p>

                <h2>Having Trouble?</h2>

                <ol>
                        <li>Did you use the correct email?</li>
                        <li>Are you checking the right email?</li>
                        <li>You may need to check your email spam folder.</li>
                        <li>Your email may be delayed.</li>
                </ol>

                <p>Try again?</p>
                <PasswordForgetLink/>

                <p>Still having issues? Please <a href="mailto:info@ceca-mcp.com" target="_blank" rel="noopener noreferrer">contact support</a></p>

        </div>
        </Layout>

)


export default PasswordResetForm;

